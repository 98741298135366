import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../config/app-config';
import {AuthService} from '../../services/auth.services';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  private isRedirecting = false;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private authService: AuthService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const baseUrl = document.baseURI.split('/').slice(0, 3).join('/');

    if (this.isRedirecting) {
      return new Observable<HttpEvent<any>>();
    }

    if (!token) {
      this.isRedirecting = true;
      window.location.replace(`${this.config.authUiUrl}/login?redirect=${window.location.href}`);
      return new Observable<HttpEvent<any>>();
    }
    
    if (token && !this.authService.isAllowedInstance(token, baseUrl)) {
      this.isRedirecting = true;
      alert('No tienes permisos para acceder a esta página.');
      window.location.replace(`${this.config.authUiUrl}/login?redirect=${window.location.href}`);
      return new Observable<HttpEvent<any>>();
    }

    const apiKey = this.config.managementApiKey;
    req = req.clone({
      setHeaders: { 'X-Api-Key': apiKey },
    });

    return next.handle(req);
  }
}