import {EdcUiThemeConfig} from './edc-ui-theme-config';

export const CUSTOM_THEME: EdcUiThemeConfig = {
  theme: 'theme-custom',
  brandFaviconSrc: '/assets/images/datavoice_favicon.png',
  brandLogoSrc: '/assets/images/datavoice_logo.png',
  brandLogoStyle: 'width: 90%; margin: 0 auto;',
};

export const MDS_THEME: EdcUiThemeConfig = {
  theme: 'theme-mds',
  brandFaviconSrc: '/assets/images/mds_favicon.ico',
  brandLogoSrc: '/assets/images/mds_logo.svg',
  brandLogoStyle: 'height: 57px; margin-top: 5px; margin-left: 5px;',
};
