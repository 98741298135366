import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  getToken(): string | null {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ').reduce((acc, cookie) => {
      const [key, value] = cookie.split('=');
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {} as Record<string, string>);
    return cookies['token'] || null;
  }

  decodeToken(token: string): any {
    try {
      const payload = token.split('.')[1];
      return JSON.parse(atob(payload));
    } catch (error) {
      console.error('Error al decodificar el token:', error);
      return null;
    }
  }

  isAllowedInstance(token: string, baseUrl: string): boolean {
    try {
      const allowedInstances = this.getAllowedInstances(token);
      return allowedInstances.includes(baseUrl) || allowedInstances.includes('all');
    } catch (error) {
      console.error('Error al validar el token:', error);
      return false;
    }
  }

  getAllowedInstances(token: string): string[] {
    const decodedToken = this.decodeToken(token);
    return decodedToken?.allowedInstances || [];
  }

  isAdmin(token: string): boolean {
    const decodedToken = this.decodeToken(token);
    return decodedToken?.role === 'admin';
  }
}